<template>
    <vx-card title="Supplier Return Handovers">
        <div class="flex vx-input-group">
            <vs-tabs>
                <vs-tab label="Handover">
                    <tab tab="handover"></tab>
                </vs-tab>
                <vs-tab label="Completed">
                    <tab tab="completed"></tab>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import Tab from "./components/Tab.vue";
export default {
    components: {
        Tab,
    },
    data() {
        return {}
    },
    methods: {

    }
}
</script>
