<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="flex items-center justify-between p-3 font-medium border border-solid rounded-full cursor-pointer d-theme-border-grey-light d-theme-dark-bg">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <!-- <vs-th v-if="status=='handover'">
                    <vs-checkbox @click.stop="addAllChecked()" v-model="checkedAll">Select All</vs-checkbox>
                </vs-th> -->
                <vs-th><vs-checkbox v-if="status=='handover'" @click.stop="addAllChecked()" v-model="checkedAll"></vs-checkbox>Action {{ status }}</vs-th>
                <vs-th sort-key="code">SR Data </vs-th>
                <vs-th sort-key="code">Supplier Data</vs-th>
                <vs-th sort-key="">Picking List Data</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <!-- <vs-td v-if="status=='handover'">
                        <div class="flex vx-input-group">
                            <vs-checkbox 
                                v-on:click="addChecked(indextr, tr.id)"
                                :checked="checked.includes(tr.id)" 
                                color="primary"
                            ></vs-checkbox>
                        </div>
                    </vs-td> -->
                    <vs-td>
                        <div class="flex vx-input-group">
                            <vs-checkbox 
                                v-if="status=='handover'"
                                v-on:click="addChecked(indextr, tr.id)"
                                :checked="checked.includes(tr.id)" 
                                color="primary"
                            ></vs-checkbox>
                            <vs-button 
                                v-if="status=='handover'" 
                                size="small" color="success" 
                                icon-pack="feather" 
                                icon="icon-box" 
                                title="Handover"
                                style="margin-right: 5px" 
                                @click="handleHandover(tr.id)"
                            />
                            <vs-button 
                                size="small" 
                                color="warning" 
                                icon-pack="feather" 
                                icon="icon-eye" 
                                title="Detail"
                                style="margin-right: 5px" 
                                @click="handleDetail(tr.id)"
                            />
                            <vs-button
                                size="small"
                                v-if="status=='handover'"
                                color="success"
                                icon-pack="feather"
                                icon="icon-check"
                                title="Complete"
                                style="margin-right: 5px"
                                @click="handleComplete(tr.id)"
                            />
                        </div>
                    </vs-td>
                    <vs-td>
                        <div class="flex flex-col content-center">
                            <div>SR Code : {{ tr.return_code }}</div>
                            <div>GRR Code : {{ tr.grr_code }}</div>
                            <div>SR Code External : {{ tr.sr_ref ? tr.sr_ref : '' }}</div>
                            <div>GRR Code External : {{ tr.ref_code_external ? tr.ref_code_external : '' }}</div>
                            <div>Type : {{ Number(tr.return_type) == 0 ? 'With Reference' : 'Without Reference' }}</div>
                            <div v-if="tr.id > 0">Status : {{ tr.status }}</div>
                        </div>
                    </vs-td>
                    <vs-td key="supplier">
                        <div class="flex flex-col content-center">
                            <div>Code : {{ tr.supplier_code }}</div>
                            <div>Name : {{ tr.supplier_name }}</div>
                            <div>City : {{ tr.supplier_city }}</div>
                        </div>
                    </vs-td>
                    <vs-td key="warehouse">
                        <div v-for="(pick, i) in tr.picking_list" :key="i" class="flex flex-col content-center">
                            <vs-chip>{{ `${pick.item_unit.item_name} - total: ${pick.qty} ${pick.item_unit.name}` }} </vs-chip>
                        </div>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <div class="mb-6 space-x-1 flex">
            <vs-button 
                v-on:click="submit()" 
                color="primary" 
                icon-pack="feather"
                icon="icon-inbox"
                v-if="status === 'handover'"
            >Submit</vs-button>
        </div>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        srId: {
            type: Number,
        },
        supplierId: {
            type: Number,
        },
        rangeDate: {
            default: null,
        },
        status: {
            type: String,
            default: "handover",
        },
    },
    data() {
        return {
            deleteId: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            timeOuts: null,
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "date",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleComplete(id) {
            this.$vs.dialog({
                type: "confirm",
                color: "success",
                title: `Confirm`,
                text: "Are you sure to complete this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http .patch(`${this.$store.state.outbound.supplierReturn.baseUrlPath}/${id}/completed`)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: "The data was marked as completed",
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Error",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                        }

                        this.$vs.loading.close();
                        this.getData();
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
                },
            });
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.getData, 900);
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        getData() {
            this.$vs.loading();

            var params = {
                limit: this.table.length,
                page: this.table.page,
                query: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                id: this.srId,
                supplier_id: this.supplierId,
                status: this.status,
            }
            if (this.rangeDate) {
                params.start_date = this.rangeDate.startDate
                params.end_date = this.rangeDate.endDate
            }

            this.$http.get(this.$store.state.outbound.supplierReturn.baseUrlPath, {
                params: params,
            })
            .then((resp) => {
                if (resp.code == 200) {
                    this.checkedAll = false;
                    this.checked = [];

                    this.table.total = resp.data.total_rows;
                    this.table.totalPage = resp.data.total_pages;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.limit;
                    this.table.data = resp.data.rows;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: "danger",
                        title: "Error",
                        text: resp.message,
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;
            if (valStart > this.table.total) {
                valStart = 1;
            }

            if (this.table.total == 0) {
                valStart = 0;
            }

            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (this.table.totalSearch < this.table.total && this.table.search != "") {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleHandover(id) {
            this.$router.push({
                name: this.$store.state.outbound.supplierReturn.baseRouterName + ".handover-edit",
                params: { id: id },
            });
        },
        handleDetail(id) {
            this.$router.push({
                name: this.$store.state.outbound.supplierReturn.baseRouterName + ".handover-detail",
                params: { id: id },
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        addChecked(index, val) {
            if (this.checked.includes(val)) {
                this.checked.splice(this.checked.indexOf(val), 1);
            } else {
                this.checked.push(val);
            }
        },
        addAllChecked() {
            if (this.checkedAll == false) {
                for (var i = 0; i < this.table.data.length; i++) {
                    if (this.checked.includes(this.table.data[i].id)) {
                        //
                    } else {
                        this.checked.push(this.table.data[i].id);
                    }
                }
            } else {
                for (var j = 0; j < this.table.data.length; j++) {
                    this.checked.splice(this.checked.indexOf(this.table.data[j].id), 1);
                }
            }
        },
        submit() {
            if (this.checked.length > 0) {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure to complete these data(s)?",
                    accept: () => {
                        let param = {
                            sr_id: this.checked
                        }

                        this.$vs.loading();
                        this.$http.patch(`${this.$store.state.outbound.supplierReturn.baseUrlPath}/0/completed`, param)
                        .then((resp) => {
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    color: "success",
                                    title: "Success",
                                    text: "The data was marked as completed",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            } else {
                                this.$vs.notify({
                                    color: "danger",
                                    title: "Error",
                                    text: resp.message,
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-x-circle",
                                });
                            }

                            this.$vs.loading.close();
                            this.getData();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                    }
                })
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "At least 1 checkbox required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            }
        },
    },
    mounted() { },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
};
</script>
  